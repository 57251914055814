<script lang="ts">
    import { fly } from "svelte/transition";
    import { helpCameraSettingsVisibleStore } from "../../Stores/HelpSettingsStore";
    import { getNavigatorType, isAndroid as isAndroidFct, NavigatorType } from "../../WebRtc/DeviceUtils";
    import { LL } from "../../../i18n/i18n-svelte";
    import { gameManager } from "../../Phaser/Game/GameManager";
    import logoImg from "../images/logo.png";

    let isAndroid = isAndroidFct();
    let isFirefox = getNavigatorType() === NavigatorType.firefox;
    let isChrome = getNavigatorType() === NavigatorType.chrome;

    function refresh() {
        window.location.reload();
    }

    function close() {
        helpCameraSettingsVisibleStore.set(false);
    }

    function getBackgroundColor() {
        if (!gameManager.currentStartedRoom) return undefined;
        return gameManager.currentStartedRoom.backgroundColor;
    }
</script>

<form
    class="helpCameraSettings tw-z-[600] tw-h-[70%] tw-overflow-y-auto tw-mb-2 tw-bg-dark-purple tw-rounded tw-text-white tw-self-center tw-p-3 tw-pointer-events-auto tw-flex tw-flex-col tw-m-auto tw-w-full md:tw-w-2/3 2xl:tw-w-1/2 tw-text-sm md:tw-text-base"
    style={getBackgroundColor() ? `background-color: ${getBackgroundColor()};` : ""}
    transition:fly={{ y: -50, duration: 500 }}
>
    <section class="tw-mb-0 tw-relative">
        <div class="hide">
            <button type="button" class="close-window" on:click={close}>&#215;</button>
        </div>
        <section class="tw-h-fit tw-max-w-2xl tw-self-center tw-my-5 tw-mx-auto">
            <img src={logoImg} alt="logo" class="main-logo tw-w-full" />
        </section>
        <div class="tw-px-5 tw-py-4">{@html $LL.camera.help.content()}</div>
    </section>
</form>

<style lang="scss">
    .hide {
        top: 13px;
        position: absolute;
        right: 12px;
        width: fit-content;
        height: fit-content;
        .close-window {
            height: 1.6rem;
            width: 1.6rem;
            position: initial;
            cursor: pointer;
        }
    }
</style>