import { writable, derived } from "svelte/store";
import { BannerEvent } from "./../Api/Events/Ui/BannerEvent";
import { v4 as uuid } from "uuid";

export const userMovingStore = writable(false);

export const requestVisitCardsStore = writable<string | null>(null);

export const userIsAdminStore = writable(false);
export const userIsEditorStore = writable(false);

export const userIsJitsiDominantSpeakerStore = writable(false);

export const jitsiParticipantsCountStore = writable(0);

export const limitMapStore = writable(false);

export const userHasAccessToBackOfficeStore = derived(
    [userIsAdminStore, userIsEditorStore],
    ([$userIsAdminStore, $userIsEditorStore]) => {
        return $userIsAdminStore || $userIsEditorStore;
    }
);

export const bannerStore = writable<BannerEvent | null>(null);

export interface roomItem {
    id: string;
    u_id: string;
    playerName: string;
}

function createRoomStore() {
    const { subscribe, update } = writable<roomItem[]>([]);

    return {
        subscribe,
        addRoom(u_id: string, playerName: string) {
            update((list) => {
                list.push({
                    id: uuid(),
                    u_id: u_id,
                    playerName: playerName
                });
                return list;
            });
        },
        reInitialize() {
            update(() => {
                return [];
            });
        },
    };
}

export const roomStore = createRoomStore();