<script lang="ts">
    import { get } from "svelte/store";
    import { fly } from "svelte/transition";
    import {
        NPCChatVisibilityStore,
        advisorName,
        NPCPlayerName,
        NPCChatMessageStore,
        NPCGroupList,
    } from "../../Stores/ChatStore";
    import { SendIcon } from "svelte-feather-icons";
    import { HtmlUtils } from "../../WebRtc/HtmlUtils";
   
    let input: HTMLElement;
    let htmlMessageText = "";
    //let backend_api_url = "http://127.0.0.1:8000/";
    let backend_api_url = "https://api.virtualaicorp.com/"; // TODO: Move to config file

    function reInitialize() {
        NPCChatMessageStore.reInitialize();
    }

    function handlerKeyDown(keyDownEvent: KeyboardEvent) {        
        if (keyDownEvent.key === "Enter" && !keyDownEvent.shiftKey) {            
            sendMessage();
            return false;
        }

        return true;
    }
    
    NPCChatMessageStore.subscribe(val => {
        if (NPCChatVisibilityStore) {
            setTimeout(() => {
                if (get(NPCChatVisibilityStore)) {
                    input.innerHTML = "";
                    const hh : number = document.getElementById("timeLine-messageList")?.scrollHeight ?? 0;
                    document.getElementById("timeLine-messageList")?.scrollTo(0, hh);
                }                
        }, 10);   
        }             
    })

    function closeChat() {
        NPCChatVisibilityStore.set(false);
    }
    function onKeyDown(e: KeyboardEvent) {
        if (e.key === "Escape" && $NPCChatVisibilityStore) {
            closeChat();
        }
    }

    function sendMessage() {        
        if (htmlMessageText) {
            htmlMessageText = input.textContent ? input.textContent.trim() : "";
            const message = htmlMessageText.replace(/<div>|<br>/g, "\n").replace(/(<([^>]+)>)/gi, "");
            htmlMessageText = "";
            const today = new Date();
            let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();            
            NPCChatMessageStore.addPersonalMessage(message, "me", time.toString(), get(NPCPlayerName));
            fetchDataFromAPI(message, get(NPCPlayerName), time).then(data => {
                const responseMessage = data["message"];
                const sender = data["persona_name"];
                time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                
                NPCChatMessageStore.addPersonalMessage(responseMessage, "NPC", time.toString(), sender);
            }).catch(error => {
                console.error('Error fetching data from API:', error);
            });
        }
    }

    async function fetchDataFromAPI(userMessage: string, persona_name: string, time: string) {
        //Get local user from local storage and send it's uuid backend as session_id
        var localUser = "default";
        try {
            const localUserString = localStorage.getItem('localUser');
            if (localUserString) {
                localUser = JSON.parse(localUserString).uuid;
            } else {
                console.log('localUser is null or not found');
            }
        } catch (error) {
            console.error('Parsing error:', error);
        }

        //Send Chat API request
        const response = await fetch(backend_api_url + "chat/", {
            method: 'POST',
            body: JSON.stringify({"session_id": localUser, "name": persona_name, "message": userMessage, "current_datetime": time.toString()}),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    }

    if (NPCChatVisibilityStore) {
        reInitialize();
    }

    $: NPCList = Array.from($NPCGroupList)

    function handleUserChange(event: Event) {
        const target = event.target as HTMLSelectElement;
        const activeNPCName = target.value
        NPCPlayerName.set(activeNPCName);
    }

    const body = document.getElementsByTagName('body')[0];

    body.addEventListener('click', (e) => {
        document.getElementById('user_select')?.blur();
    })

    body.addEventListener('mouseup', (e) => {
        document.getElementById('user_select')?.blur();
    })

    body.addEventListener('mousedown', (e) => {
        document.getElementById('user_select')?.blur();
    })
</script>

<svelte:window/>
<div id="NPCChatWindow" class:show={$NPCChatVisibilityStore}>
    <div
        id="activeTimeline"
        class="tw-flex tw-flex-col tw-h-full tw-min-h-full tw-over tw-w-full"
        transition:fly={{ x: 500, duration: 400 }}
    >
        <div class="wa-thread-head">
            {#if $NPCChatVisibilityStore}<div class="hide" style="z-index: 999;">
                <button class="close-window" on:click={closeChat}>&#215;</button>
            </div>{/if}
            <div class="title">
                <div class="tw-text-center tw-pt-2 tw-pb-3 NPC-chat-header">
                    <div class="tw-flex tw-justify-center">
                        <b class="NPC-chat-title">Chat Window</b>
                    </div>
                    <div class="tw-flex tw-flex-wrap tw-gap-x-1 tw-items-center tw-text-xs tw-text-lighter-purple NPC-chat-sub-title">
                        Name: {$NPCPlayerName}
                    </div>
                </div>
            </div>
        </div>        
        <div  style="border-bottom: 1px solid rgb(77 75 103 / 1); display: flex; padding: 15px; gap: 15px;">
            <h5 class="sub-title">Chatting with </h5>
            <select on:change={handleUserChange} class="user-list" id="user_select" value="${NPCPlayerName}" style="width: 210px; margin-bottom: 0px;" aria-hidden="true">
                {#each NPCList as npc, index}
                    <option value="{npc.NPCPlayerName}">{npc.NPCPlayerName}</option>
                {/each}
            </select>
        </div>
        <hr style="color: #ffffff;">
        <!-- MESSAGE LIST-->
        <div
            id="timeLine-messageList"
            class="tw-flex tw-flex-col tw-flex-auto tw-px-5 tw-justify-end tw-h-auto tw-min-h-screen"
        >
        {#each $NPCChatMessageStore as message, i (message.id)}
            {#if message.authorName == $NPCPlayerName }
                <div
                    id={`message_${message.id}`}
                    class="tw-mt-2"
                >
                    <div
                        class={`tw-flex ${
                            message.type === "me" ? "tw-justify-end" : "tw-justify-start"
                        }`}
                    >
                        <div class="tw-w-3/4">
                            {#if message.text}
                                <div class="wa-message-body">
                                    {#if message.type == "me"}
                                        From {$advisorName}:
                                    {:else}
                                        From  {$NPCPlayerName}:
                                    {/if}                                    
                                    {#each message.text as text (text)}
                                        <div
                                            class="tw-text-ellipsis tw-overflow-y-auto tw-break-words tw-whitespace-pre-line disable-scrollbar"
                                        >
                                            {#await HtmlUtils.urlify(text)}
                                                <p>...waiting</p>
                                            {:then html}
                                                {@html html}
                                            {/await}
                                        </div>
                                    {/each}
                                </div>
                            {/if}
                        </div>
                    </div>
                </div>
            {/if}
        {/each}
        </div>
        <!--MESSAGE FORM-->
        <div class="wa-message-form">                
            <form class="tw-flex tw-flex-col tw-m-auto tw-w-[75%]">
                <div class="tw-w-full tw-px-2 tw-pb-2 tw-flex tw-flex-row tw-justify-center tw-items-center">
                    <div
                        bind:this={input}
                        contenteditable="true"
                        bind:innerHTML={htmlMessageText}
                        data-placeholder="Enter a message ..."
                        on:keydown={handlerKeyDown}
                        class="msg-input" style="height: 58px; max-height: 58px;"
                    />
                    <div class="actions tw-px-2 tw-py-2">
                        <div class="tw-flex tw-items-center tw-space-x-1">
                            <button
                                id="send"
                                type="button"
                                class="can-send tw-bg-transparent tw-p-0 tw-m-0 tw-inline-flex tw-justify-center tw-items-center tw-text-light-blue"
                                on:click|stopPropagation={sendMessage}
                            >
                                <SendIcon size="17" />
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<style lang="scss">
    @import "../../style/breakpoints.scss";

    @include media-breakpoint-up(sm) {
        #NPCChatWindow {
            width: 100% !important;
        }
    }

    .wa-message-body {
        min-width: 75px;
        position: relative;
        word-break: break-word;;
        border-radius: 0.5rem;
        padding: 0.5rem 0.75rem;
        text-align: left;
        font-size: 1rem; //0.75rem;
        line-height: 1.25rem;
        background-color: rgb(15, 31, 45);
        color: white;
    }

    #activeTimeline {
        position: absolute;
        min-height: calc(100vh - 8.8rem);
    }

    #NPCChatWindow {
        z-index: 1000;
        position: absolute;
        background-color: rgba(27, 27, 41, 0.79);
        top: 0;
        left: -100%;
        height: 100%;
        width: 40%;
        min-width: 335px;
        transition: all 0.2s ease-in-out;
        pointer-events: none;
        visibility: hidden;
    
        &.show {
            left: 0;
            pointer-events: auto;
            visibility: visible;
        }
        iframe {
            width: 100%;
            height: 100%;
        }
        .hide {
            top: 13px;
            position: absolute;
            right: 12px;
            width: fit-content;
            height: fit-content;
            .close-window {
                height: 1.6rem;
                width: 1.6rem;
                position: initial;
                cursor: pointer;
            }
        }
    }    

    .msg-input {
        width: 100%;
        padding-right: 0.5rem !important;
    }
    aside.chatWindow {
        pointer-events: auto;
        color: whitesmoke;
        display: flex;
        flex-direction: column;
    }

    .settingsHeader {
        padding-top: 58px;
    }
    .messageList {
        display: flex;
        justify-content: flex-end;
        overflow-y: hidden;
        min-height: calc(100vh - 40px);
        padding: 60px 0;
    }
    form .actions {
        top: 10px;
    }
    form [contenteditable="true"] {
        padding-right: 4rem;
    }
    .wa-thread-head {

        position: relative;
    }

    .wa-dropdown-menu {
        margin: 0 0 0 10px;
        position: relative;
        width: 94%;
        max-height: 50vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    input {
        border-color: #879fc2;
        margin-bottom: 0px;
    }
    .wa-message-form {
        width: 40%;
        min-width: 335px;
        bottom: 3rem;
        position: fixed;
        .actions {
            .action {
                cursor: pointer;
                opacity: 0.8;
                position: relative;
                .caption {
                    @apply tw-absolute tw-bg-dark-blue tw-text-sm tw-px-2 tw-py-1 tw-rounded-xl tw-border-lighter-purple tw-border tw-border-solid;
                    display: none;
                    top: 5px;
                    left: 54px;
                    z-index: 10;
                    width: max-content;
                    &::before {
                        @apply tw-absolute tw-border-lighter-purple;
                        bottom: -10px;
                        content: "";
                        width: 0;
                        height: 0;
                        border-left: 9px solid transparent;
                        border-right: 9px solid transparent;
                        border-top-width: 6px;
                        border-top-style: solid;
                    }
                    &::after {
                        @apply tw-absolute tw-border-dark-blue;
                        bottom: -10px;
                        content: "";
                        width: 0;
                        height: 0;
                        border-left: 7px solid transparent;
                        border-right: 7px solid transparent;
                        border-top-width: 5px;
                        border-top-style: solid;
                    }
                }
                &:hover {
                    opacity: 1;
                    .caption {
                        display: block;
                    }
                }
            }
        }
        &:hover {
            .actions {
                visibility: visible;
            }
        }
    }
    #timeLine-messageList {
        width: 100%;
        min-width: 335px;
        min-height: auto;
        max-height: calc(100vh - 10rem - 120px);
        overflow-x: hidden;
        overflow-y: auto;
        display: block !important;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    #timeLine-messageList::-webkit-scrollbar {
        display: none;
    }
    
    .NPC-chat-header {
        width: 100%;
        position: relative;
    }
    .NPC-chat-title {
        color: white;
    }

    .NPC-chat-sub-title {
        display: flex;
        justify-content: center;
    }
    .sub-title {
        color: #c1b7b7;
        text-align: center;
        padding: 10px 0;
    }

    .disable-scrollbar {
        overflow: hidden;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
</style>
